@import '../../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 25px;
  }
}

.title::after {
  padding-top: 32px;
  display: flex;
  content: '';
  border-bottom: 2px solid #c5950c;
  width: 32px;
  margin-left: auto;
  margin-right: auto;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width:324px;
  @media (--viewportMedium) {
    /* margin-right: 40px; */
    width: calc(100% / 3 - 12px);
  }
}
.stepText {
  text-align: left;
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  transition: var(--transitionStyleButton);
  margin-bottom: 90px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: 12px;
}

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  object-fit: cover;
}

@import '../../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 25px;
  }
}

.title::after {
  padding-top: 32px;
  display: flex;
  content: '';
  border-bottom: 2px solid #c5950c;
  width: 32px;
  margin-left: auto;
  margin-right: auto;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 324px;
  @media (--viewportMedium) {
    width: 843px;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.stepImage {
  width: 100%;
  max-width: 843px;
  display: block;
  margin: 0 auto;
}

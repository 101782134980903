@import '../../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url('../../../assets/fv-background.jpg');
  background-color: var(--matterColor);
  background-size: cover;
  background-position: center center;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  }
}

.heroContent {
  margin: 0 24px 0 24px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
    text-align: center;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 40px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  margin: 0 auto 6px;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    font-size: 40px;
    margin-top: 6px;
  }
}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  @apply --marketplaceH3FontStyles;

  color: var(--matterColorLight);
  margin: 0 auto 32px ;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 auto 47px ;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroTopSubTitle {
  @apply --marketplaceH3FontStyles;

  color: var(--matterColorLight);
  margin: 0 auto 6px;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin-bottom: 6px;
    margin-top: 6px;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  border-radius: 80px;
  background-color: #c5950c;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: block;
    width: 260px;
    border-radius: 80px;
    background-color: #c5950c;
    color: var(--matterColorLight);
  }
  &:hover {
    background-color: var(--matterColorLight);
    color: #c5950c;
  }
}
.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.heroBtns {
  width: 235px;
  height: calc(60px + 60px + 16px);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    width: calc(260px + 260px + 32px);
    height: 60px;
    justify-content: space-between;
  }
}

@import '../../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 25px;
  }
}

.title::after {
  padding-top: 32px;
  display: flex;
  content: '';
  border-bottom: 2px solid #c5950c;
  width: 32px;
  margin-left: auto;
  margin-right: auto;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  text-align: center;
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.listBlock {
  list-style-type: none;
  display: table;
  width: 100%;
  height: 36px;
  padding: 0;
  margin: 0;
  overflow: hidden;

  @media (--viewportMedium) {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
}
.listBlock li {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #c5950c;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  position: relative;
  border: 1px solid var(--matterColorLight);
  margin-bottom: 30px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: center;
    width: 190px;
    height: 100px;
    position: relative;
    background: #c5950c;
    color: #fff;
    padding-left: 12px;
    border: 1px solid #c5950c;
    margin-bottom: 0;
  }
}

.listBlock li:last-child{
  margin-bottom: 0;
}

.listBlock li span {
  color: #fff;
  line-height: 16px;
  padding-top: 4px;

  @media (--viewportMedium) {
    color: #fff;
    line-height: 16px;
  }
}

.listBlock li:after {
  content: '';
  top: 100%;
  right: 0;
  left: 0;
  position: absolute;
  width: 0;
  height: 0;
  margin: 8px auto;
  border-style: solid;
  border-color: #c5950c transparent transparent transparent ;
  border-width: 14px 14px 0 14px;
  z-index: 1;

  @media (--viewportMedium) {
    content: '';
    top: -8px;
    right: -24px;
    left: auto;
    position: absolute;
    width: 0;
    height: 0;
    margin: auto;
    border-style: solid;
    border-color: transparent transparent transparent #c5950c;
    border-width: 60px 0 60px 24px;
    z-index: 1;
  }
}

.listBlock .listItem {
  height:70px;
  @media (--viewportMedium) {
    height: 100px;
  }
}
.listItemCol {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    width: 150px;
  }
}
.listItemCol span {
  font-size: 12px;
}

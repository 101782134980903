@import '../../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 25px;
  }
}

.title::after {
  padding-top: 32px;
  display: flex;
  content: '';
  border-bottom: 2px solid #c5950c;
  width: 32px;
  margin-left: auto;
  margin-right: auto;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 324px;
  @media (--viewportMedium) {
    width: 843px;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.image img {
  width: 100%;
}

.block {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}

.blockSteps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    /* max-width: 480px; */
  }
}
.blockStep {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 324px;
  @media (--viewportMedium) {
    width: calc(100% / 2 - 24px);
  }
}

.blockStep p{
  text-align: left;
}
